import React, { useState, useEffect, useRef } from 'react'
import ThreadHistory from "./ThreadHistory.js";
import { present } from './utils.js'

function ThreadHistoryPopup(props) {
  const { assoc, prospect, children, activePopup, onPopupActivate, index, offsetText } = props

  let outreach = assoc.linkedInOutreach
  let campaign = assoc.campaign

  let displayOn = props.displayOn || "left"

  let initialDiamondPosition, popupOffset
  if (displayOn === 'left') {
    popupOffset = -620
    initialDiamondPosition = { top: '45%', left: '100%' }
  } else {
    if (offsetText) {
      popupOffset = offsetText.length * 9
    } else {
      popupOffset = 100
    }

    initialDiamondPosition = { top: '45%', left: '-1%' }
  }

  const popupKey = `${prospect.id}-${index}`
  const [showPopup, setShowPopup] = useState(false);
  const [popupPosition, setPopupPosition] = useState({ top: 0, left: popupOffset });
  const [diamondPosition, setDiamondPosition] = useState(initialDiamondPosition);
  const [shouldHidePopup, setShouldHidePopup] = useState(false);
  const [preventHiding, setPreventHiding] = useState(false);
  const [displayedThread, setDisplayedThread] = useState(0)

  const linkRef = useRef(null);

  const handleMouseEnter = (e) => {
    setShowPopup(true);
    setPreventHiding(true);
    setShouldHidePopup(false)
    calculatePopupPosition(e);
    onPopupActivate(popupKey)
  };

  const handleMouseLeave = () => {
    setPreventHiding(false)
    setTimeout(() => {
      setShouldHidePopup(true);
    }, 200);
  };

  useEffect(() => {
    if (shouldHidePopup && !preventHiding) {
      setShowPopup(false)
    } else if (shouldHidePopup && preventHiding && showPopup) {
      setShouldHidePopup(false)
      setPreventHiding(false)
    }
  }, [shouldHidePopup]);

  useEffect(() => {
    if (showPopup) {
      calculatePopupPosition()
    }
  }, [showPopup]);

  const calculatePopupPosition = () => {
    const popup = document.getElementById('popup');

    const rect = linkRef.current.getBoundingClientRect();
    const windowHeight = window.innerHeight;
    if (!popup) return;
    const popupHeight = popup.offsetHeight;

    let top = popup.offsetHeight * -0.45
    if (rect.top + popupHeight > windowHeight) {
      top = (windowHeight - (rect.top + popupHeight)) - 10;
    }

    if (displayOn === 'left') {
      setDiamondPosition({ top: (top * -1) + 2, left: '99%' })
      setPopupPosition({ top: top, left: popupOffset + 7 });
    } else {
      setDiamondPosition({ top: (top * -1) + 2, left: '-1%' })
      setPopupPosition({ top: top, left: popupOffset + 7 });
    }
  };

  if (!present(outreach)) return null;
  if (!present(outreach.threadUrl)) return null;
  if (!present(outreach.linkedInOutreachThreads)) return null;

  if (outreach.repliedVia === "email") {
    return (
      <div>
        <a href={`https://mail.google.com/mail/u/0/#inbox/${outreach.emailThreadId}`} target="_blank">{ campaign.linkedInAccount.name } (EMAIL REPLIED)</a>
      </div>
    );
  }

  let threadsRender = outreach.linkedInOutreachThreads.map((thread, i) => (
    <div key={i}>
      <ThreadHistory
        key={i}
        threadId={thread.linkedInThread.id}
        history={thread.linkedInThread.threadHistory}
        linkedInAccount={campaign.linkedInAccount}
        prospect={prospect}
        visible={i === displayedThread}
        sticky={true}
      />
      {outreach.linkedInOutreachThreads.length > 1 && (
        <div
          href='#'
          onClick={() => setDisplayedThread(i)}
          className='popup-diamond'
          style={{
            position: "absolute",
            top: 40,
            left: 290 + (i * 20),
            background: i === displayedThread ? "rgba(223, 223, 223)" : "white",
            cursor: "pointer"
          }}
        ></div>
      )}
    </div>
  ))

  return (
    <div className="popup-container" onMouseLeave={() => handleMouseLeave()}>
      <div onMouseOver={handleMouseEnter} ref={linkRef}>
        {children}
      </div>
      {activePopup === popupKey && showPopup && (
        <div
          className="popup"
          id="popup"
          style={{ top: `${popupPosition.top}px`, left: `${popupPosition.left}px` }}
          onMouseEnter={() => setPreventHiding(true)}
        >
          {threadsRender}
          {console.log('popupPosition', diamondPosition)}
          <div className="popup-diamond"  style={{ top: `${diamondPosition.top}px`, left: `${diamondPosition.left}` }}></div>
        </div>
      )}
    </div>
  );
}

export default ThreadHistoryPopup;
