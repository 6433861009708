import React, { useEffect, useState, forwardRef } from "react";
import { Form, Grid, Dropdown, Segment } from 'semantic-ui-react'
import DatePicker from "react-datepicker";
import { present, } from "../utils.js";
import { BrowserRouter as Router, withRouter, } from "react-router-dom";
import moment from "moment";
import qs from "qs";

function psQ(q) {
  return qs.parse(q.substring(1), { arrayFormat: 'brackets' })
}

const CrmStatusHistory = (props = {}) => {
  let query = psQ(props.location.search)
  query.crmStatusHistory ||= {}
  query.crmStatusHistory.afterHaving ||= {}

  const [dateRange, setDateRange] = useState({ from: Date.parse(query.crmStatusHistory?.afterHaving?.from) || null, to: Date.parse(query.crmStatusHistory?.afterHaving?.to) || null });
  const [statusError, setStatusError] = useState(false);
  let { crmStatuses, campaigns, users, companyId } = props;

  useEffect(() => {
    let currentQuery = psQ(props.location.search)

    currentQuery.crmStatusHistory ||= {}
    currentQuery.crmStatusHistory.afterHaving ||= {}

    if (dateRange.from && dateRange.to) {
      currentQuery.crmStatusHistory.afterHaving.from = moment(dateRange.from).toISOString()
      currentQuery.crmStatusHistory.afterHaving.to = moment(dateRange.to).toISOString()
    } else {
      delete currentQuery.crmStatusHistory.afterHaving.from
      delete currentQuery.crmStatusHistory.afterHaving.to
    }

    handleQueryChange(currentQuery)
  }, [dateRange])

  const handleQueryChange = (query) => {
    if (Object.values(query.crmStatusHistory.afterHaving)?.every((v) => !present(v))) {
      delete query.crmStatusHistory.afterHaving
    }

    if (Object.values(query.crmStatusHistory)?.every((v) => !present(v))) {
      delete query.crmStatusHistory
    }

    if (!present(query.crmStatusHistory?.afterHaving?.status) && dateRange.to && dateRange.from) {
      setStatusError(true)
    }

    if (present(query.crmStatusHistory?.afterHaving?.status)) {
      setStatusError(false)
    }

    if (!present(query.crmStatusHistory?.afterHaving?.status) && (!dateRange.to || !dateRange.from)) {
      setStatusError(false)
    }

    let newQueryString = qs.stringify(query, { arrayFormat: 'brackets' });
    props.history.push(props.location.pathname + "?" + newQueryString)
  }

  const CustomDatepickerFromInput = forwardRef(({ onClick, value, onChange }, ref) => (
    <>
      <input type="text" onClick={onClick} onChange={onChange} value={value} className="react-datepicker-ignore-onclickoutside" ref={ref} />
      { dateRange.from && (
        <i className="datepicker close icon" onClick={() => setDateRange({ ...dateRange, from: null })}></i>
      )}
    </>
  ));

  const CustomDatepickerToInput = forwardRef(({onClick, onChange, value}, ref) => (
    <>
      <input type="text" onClick={onClick} onChange={onChange} value={value} className="react-datepicker-ignore-onclickoutside" ref={ref} />
      {dateRange.to && (
        <i className="datepicker close icon" onClick={() => setDateRange({ ...dateRange, to: null })}></i>
      )}
    </>
  ));

  return (
    <Segment>
      <Grid.Column width={16}>
        <h3>CRM status history</h3>
        <div>
          <Grid stackable>
            <Grid.Row columns={3}>
              <Grid.Column>
                <Form.Field>
                  <label>Showing prospects that got status</label>
                  <Dropdown
                    placeholder='Select Status'
                    fluid
                    selection
                    options={crmStatuses.map(s => ({ key: s.id, text: s.name, value: s.id }))}
                    search
                    labeled
                    clearable
                    error={statusError}
                    value={parseInt(query.crmStatusHistory?.afterHaving?.status) || null}
                    onChange={(_, data) => {
                      query.crmStatusHistory.afterHaving.status = data.value

                      handleQueryChange(query)
                    }}
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column>
                <Form.Field>
                  <label>From</label>
                  <DatePicker
                    fluid
                    selected={dateRange.from}
                    onChange={(date) => setDateRange({ ...dateRange, from: date })}
                    selectsStart
                    startDate={dateRange.from}
                    endDate={dateRange.to}
                    customInput={<CustomDatepickerFromInput />}
                    dateFormat="dd.MM.yyyy"
                    autoComplete="off"
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column>
                <Form.Field>
                  <label>To:</label>
                  <DatePicker
                    fluid
                    selected={dateRange.to}
                    onChange={(date) => setDateRange({ ...dateRange, to: date })}
                    selectsEnd
                    startDate={dateRange.from}
                    endDate={dateRange.to}
                    minDate={dateRange.from}
                    customInput={<CustomDatepickerToInput />}
                    dateFormat="dd.MM.yyyy"
                    autoComplete="off"
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
            {companyId === 1 && (
              <Grid.Row columns={2}>
                <Grid.Column>
                  <Form.Field>
                    <label>While owner was</label>
                    <Dropdown
                      fluid
                      placeholder='Select User'
                      search
                      options={users.map(s => ({ key: s.id, text: s.name, value: s.id }))}
                      selection
                      clearable
                      onChange={(_, data) => {
                        query.crmStatusHistory.afterHaving.triggeredByOwner = data.value

                        handleQueryChange(query)
                      }}
                      value={parseInt(query.crmStatusHistory?.afterHaving?.triggeredByOwner) || null}
                    />
                  </Form.Field>
                </Grid.Column>
                <Grid.Column>
                  <Form.Field>
                    <label>Triggered by a campaign</label>
                    <Dropdown
                      fluid
                      placeholder='Select Campaign'
                      search
                      options={campaigns.map(s => ({ key: s.id, text: s.name, value: s.id }))}
                      selection
                      clearable
                      onChange={(_, data) => {
                        query.crmStatusHistory.afterHaving.triggeredByCampaign = data.value

                        handleQueryChange(query)
                      }}
                      value={parseInt(query.crmStatusHistory?.afterHaving?.triggeredByCampaign) || null}
                    />
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
            )}
            <Grid.Row columns={1}>
              <Grid.Column>
                <Form.Field>
                  <label>And later got status</label>
                  <Dropdown
                    fluid
                    placeholder='Select Status'
                    search
                    options={crmStatuses.map(s => ({ key: s.id, text: s.name, value: s.id, disabled: s.id == query.crmStatusHistory?.afterHaving?.status }))}
                    selection
                    clearable
                    onChange={(_, data) => {
                      query.crmStatusHistory.hadStatus = data.value

                      handleQueryChange(query)
                    }}
                    value={parseInt(query.crmStatusHistory?.hadStatus) || null}
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>

          </Grid>
        </div>
        </Grid.Column>
      </Segment>
  )
}

export default withRouter(CrmStatusHistory);
